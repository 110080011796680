<template>

  <div class="wrapper">

    <section class="carousel-section ">
      <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">

        <div class="carousel-inner">

          <div class="carousel-item active">
            <!-- <img class="d-block w-100" src="/img/carousel/carousel_3.png"> -->
            <img class="d-block w-100" src="/img/carousel/cheza_leo.png">
          </div>

          <div class="carousel-item">
            <!-- <img class="d-block w-100" src="/img/carousel/comet-crash.png"> -->
            <img class="d-block w-100" src="/img/carousel/freespin1.png">
          </div>

          <div class="carousel-item">
            <!-- <img class="d-block w-100" src="/img/carousel/crash-mamilli.png"> -->
            <img class="d-block w-100" src="/img/carousel/djp1.png">
          </div>

          <!-- <div class="carousel-item ">
            <img class="d-block w-100" src="/img/carousel/f1.png">
          </div> -->

          <div class="carousel-item ">
            <!-- <img class="d-block w-100" src="/img/carousel/carousel_4.png"> -->
            <img class="d-block w-100" src="/img/carousel/karibu1.png">
          </div>

          <!-- <div class="carousel-item ">
            <img class="d-block w-100"
                 src="/img/carousel/JACKPOT.png">
          </div> -->

        </div>
        <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </section>

    <div>

      <section class="container">

        <!-- Title and Link -->
        <div style="background-color: var(--yellow);padding: 2px;border-radius: 5px;" class="row mt-2 mb-2">
          <div class="col-12 d-flex justify-content-between align-items-center">
            <h5 style="color: black;margin-top: 8px;">Flying High</h5>
            <router-link to="casino" class="float-right text-dark">All Games <i class="bi bi-chevron-right"></i>
            </router-link>
          </div>
        </div>

        <!-- First Image (Full Width) -->
        <div class="row justify-content-around">

          <div style="text-align: center;" class="col-sm-4 col-4 col-md-4 crash-game fly-game">
            <div class="game-icon">
              <img loading="lazy"
                style="max-width: 100%;object-fit: contain;border-radius: 16px;padding: 0;background-color: var(--lightest-gray);border: solid var(--red) 1px;"
                src="/img/home/rocketman.png" alt="Avatar" data-toggle="modal" data-target="#rocketMan"
                class="img-fluid rounded border rocket-fastball" />
            </div>
            <div class="modal fade" id="rocketMan" tabindex="-1" role="dialog" aria-labelledby="rocketManLabel"
              aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div style="background-color: var(--grey);color: white;" class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="rocketManLabel">Rocket Man</h5>
                  </div>
                  <div class="modal-body">
                    <img loading="lazy" src="/img/home/rocketman.png" alt="gameimg" class="ww-100 mb-2" />
                    <div class="text-center">
                      <router-link data-dismiss="modal" style="background-color: var(--yellow);color: var(--red);"
                        class="btn ww-100 mb-2" :to="{ name: 'casinogames', params: { IsDemo: 0, providerID: 4, gameName: 'Rocket Man', gameID: 'rocketman', launchURL: 'https://cdn.rocketman.elbet.com', launchType: '', launchParameters: '' }, }">
                        Play Now
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div style="text-align: center;" class="col-sm-4 col-4 col-md-4 crash-game fly-game">
            <div class="game-icon">
              <img loading="lazy"
                style="max-width: 100%;object-fit: contain;border-radius: 16px;padding: 0;background-color: var(--lightest-gray);border: solid var(--red) 1px;"
                src="/img/home/aviator.png" alt="Avatar" data-toggle="modal" data-target="#aviator"
                class="img-fluid rounded border rocket-fastball" />
            </div>
            <div class="modal fade" id="aviator" tabindex="-1" role="dialog" aria-labelledby="aviatorLabel"
              aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div style="background-color: var(--grey);color: white;" class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="aviatorLabel">Aviator</h5>
                  </div>
                  <div class="modal-body">
                    <img loading="lazy" src="/img/home/aviator.png" alt="gameimg" class="ww-100 mb-2" />
                    <div class="text-center">
                      <router-link data-dismiss="modal" style="background-color: var(--yellow);color: var(--red);"
                        class="btn ww-100 mb-2" :to="{ name: 'casinogames', params: { IsDemo: 0, providerID: 7, gameName: 'Aviator', gameID: 'aviator', launchURL: 'https://launch.spribegaming.com', launchType: '', launchParameters: '' }, }">
                        Play Now
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style="text-align: center;" class="col-sm-4 col-4 col-md-4 crash-game fly-game">
            <div class="game-icon">
              <img loading="lazy"
                style="max-width: 100%;object-fit: contain;border-radius: 16px;padding: 0;background-color: var(--lightest-gray);border: solid var(--red) 1px;"
                src="/img/home/JetX.png" alt="Avatar" data-toggle="modal" data-target="#jetX"
                class="img-fluid rounded border rocket-fastball" />
            </div>
            <div class="modal fade" id="jetX" tabindex="-1" role="dialog" aria-labelledby="jetXLabel"
              aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div style="background-color: var(--grey);color: white;" class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="jetXLabel">JetX</h5>
                  </div>
                  <div class="modal-body">
                    <img loading="lazy" src="/img/home/JetX.png" alt="gameimg" class="ww-100 mb-2" />
                    <div class="text-center">
                      <router-link data-dismiss="modal" style="background-color: var(--yellow);color: var(--red);"
                        class="btn ww-100 mb-2" :to="{ name: 'casinogames', params: { IsDemo: 0, providerID: 5, gameName: 'JetX', gameID: 'JetX', launchURL: 'https://eu-server.ssgportal.com', launchType: '', launchParameters: '' }, }">
                        Play Now
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>

      <section class="container">

        <div style="background-color: var(--yellow);padding: 2px;border-radius: 5px;" class="row mt-2 mb-2">
          <div class="col-12 d-flex justify-content-between align-items-center">
            <h5 style="color: black;margin-top: 8px;">Just Landed</h5>
            <router-link to="casino" class="float-right text-dark">All Games <i
                class="bi bi-chevron-right"></i></router-link>
          </div>
        </div>

        <div class="row">

          <div style="text-align: center;" class="col-sm-4 col-4 col-md-4 crash-game fly-game">
            <div class="game-icon">
              <img loading="lazy"
                style="max-width: 100%;object-fit: contain;border-radius: 16px;padding: 0;background-color: var(--lightest-gray);border: solid var(--red) 1px;"
                src="/img/home/FootballX.png" alt="Avatar" data-toggle="modal" data-target="#footballX"
                class="img-fluid rounded border rocket-fastball" />
            </div>
            <div class="modal fade" id="footballX" tabindex="-1" role="dialog" aria-labelledby="footballXLabel"
              aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div style="background-color: var(--grey);color: white;" class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="footballXLabel">FootballX</h5>
                  </div>
                  <div class="modal-body">
                    <img loading="lazy" src="/img/home/FootballX.png" alt="gameimg" class="ww-100 mb-2" />
                    <div class="text-center">
                      <router-link data-dismiss="modal" style="background-color: var(--yellow);color: var(--red);"
                        class="btn ww-100 mb-2" :to="{ name: 'casinogames', params: { IsDemo: 0, providerID: 5, gameName: 'FootballX', gameID: 'FootballX', launchURL: 'https://eu-server.ssgportal.com', launchType: '', launchParameters: '' }, }">
                        Play Now
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div style="text-align: center;" class="col-sm-4 col-4 col-md-4 crash-game fly-game">
            <div class="game-icon">
              <img loading="lazy"
                style="max-width: 100%;object-fit: contain;border-radius: 16px;padding: 0;background-color: var(--lightest-gray);border: solid var(--red) 1px;"
                src="/img/home/JetX3.png" alt="Avatar" data-toggle="modal" data-target="#jetX3"
                class="img-fluid rounded border rocket-fastball" />
            </div>
            <div class="modal fade" id="jetX3" tabindex="-1" role="dialog" aria-labelledby="jetX3Label"
              aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div style="background-color: var(--grey);color: white;" class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="jetX3Label">JetX3</h5>
                  </div>
                  <div class="modal-body">
                    <img loading="lazy" src="/img/home/JetX3.png" alt="gameimg" class="ww-100 mb-2" />
                    <div class="text-center">
                      <router-link data-dismiss="modal" style="background-color: var(--yellow);color: var(--red);"
                        class="btn ww-100 mb-2" :to="{ name: 'casinogames', params: { IsDemo: 0, providerID: 5, gameName: 'JetX3', gameID: 'JetX3', launchURL: 'https://eu-server.ssgportal.com', launchType: '', launchParameters: '' }, }">
                        Play Now
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div style="text-align: center;" class="col-sm-4 col-4 col-md-4 crash-game fly-game">
            <div class="game-icon">
              <img loading="lazy"
                style="max-width: 100%;object-fit: contain;border-radius: 16px;padding: 0;background-color: var(--lightest-gray);border: solid var(--red) 1px;"
                src="/img/home/DoubleX.png" alt="Avatar" data-toggle="modal" data-target="#doubleX"
                class="img-fluid rounded border rocket-fastball" />
            </div>
            <div class="modal fade" id="doubleX" tabindex="-1" role="dialog" aria-labelledby="doubleXLabel"
              aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div style="background-color: var(--grey);color: white;" class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="doubleXLabel">DoubleX</h5>
                  </div>
                  <div class="modal-body">
                    <img loading="lazy" src="/img/home/DoubleX.png" alt="gameimg" class="ww-100 mb-2" />
                    <div class="text-center">
                      <router-link data-dismiss="modal" style="background-color: var(--yellow);color: var(--red);"
                        class="btn ww-100 mb-2" :to="{ name: 'casinogames', params: { IsDemo: 0, providerID: 5, gameName: 'DoubleX', gameID: 'DoubleX', launchURL: 'https://eu-server.ssgportal.com', launchType: '', launchParameters: '' }, }">
                        Play Now
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>

      <section class="container">

        <div style="background-color: var(--yellow);padding: 2px;border-radius: 5px;" class="row mt-2 mb-2">
          <div class="col-12 d-flex justify-content-between align-items-center">
            <h5 style="color: black;margin-top: 8px;">Favourites</h5>
            <router-link to="casino" class="float-right text-dark">All Games <i
                class="bi bi-chevron-right"></i></router-link>
          </div>
        </div>

        <div class="row">
          <div style="text-align: center;" class="col-sm-4 col-4 col-md-4 crash-game fly-game">
            <div class="game-icon">
              <img loading="lazy"
                style="max-width: 100%;object-fit: contain;border-radius: 16px;padding: 0;background-color: var(--lightest-gray);border: solid var(--red) 1px;"
                src="/img/home/TowerX.png" alt="Avatar" data-toggle="modal" data-target="#towerX"
                class="img-fluid rounded border rocket-fastball" />
            </div>
            <div class="modal fade" id="towerX" tabindex="-1" role="dialog" aria-labelledby="towerXLabel"
              aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div style="background-color: var(--grey);color: white;" class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="towerXLabel">TowerX</h5>
                  </div>
                  <div class="modal-body">
                    <img loading="lazy" src="/img/home/TowerX.png" alt="gameimg" class="ww-100 mb-2" />
                    <div class="text-center">
                      <router-link data-dismiss="modal" style="background-color: var(--yellow);color: var(--red);"
                        class="btn ww-100 mb-2" :to="{ name: 'casinogames', params: { IsDemo: 0, providerID: 5, gameName: 'TowerX', gameID: 'TowerX', launchURL: 'https://eu-server.ssgportal.com', launchType: '', launchParameters: '' }, }">
                        Play Now
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div style="text-align: center;" class="col-sm-4 col-4 col-md-4 crash-game fly-game">
            <div class="game-icon">
              <img loading="lazy"
                style="max-width: 100%;object-fit: contain;border-radius: 16px;padding: 0;background-color: var(--lightest-gray);border: solid var(--red) 1px;"
                src="/img/home/fastballs.jpeg" alt="Avatar" data-toggle="modal" data-target="#fastBalls"
                class="img-fluid rounded border rocket-fastball" />
            </div>
            <div class="modal fade" id="fastBalls" tabindex="-1" role="dialog" aria-labelledby="fastBallsLabel"
              aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div style="background-color: var(--grey);color: white;" class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="fastBallsLabel">Fast Balls</h5>
                  </div>
                  <div class="modal-body">
                    <img loading="lazy" src="/img/home/fastballs.jpeg" alt="gameimg" class="ww-100 mb-2" />
                    <div class="text-center">
                      <router-link data-dismiss="modal" style="background-color: var(--yellow);color: var(--red);"
                        class="btn ww-100 mb-2" :to="{ name: 'casinogames', params: { IsDemo: 0, providerID: 4, gameName: 'Fast Balls', gameID: 'fastballs', launchURL: 'https://cdn.fastballs.elbet.com', launchType: '', launchParameters: '' }, }">
                        Play Now
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>




          <div style="text-align: center;" class="col-sm-4 col-4 col-md-4 crash-game fly-game">
            <div class="game-icon">
              <img loading="lazy"
                style="max-width: 100%;object-fit: contain;border-radius: 16px;padding: 0;background-color: var(--lightest-gray);border: solid var(--red) 1px;"
                src="/img/home/SpinX.png" alt="Avatar" data-toggle="modal" data-target="#spinX"
                class="img-fluid rounded border rocket-fastball" />
            </div>
            <div class="modal fade" id="spinX" tabindex="-1" role="dialog" aria-labelledby="spinXLabel"
              aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div style="background-color: var(--grey);color: white;" class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="spinXLabel">SpinX</h5>
                  </div>
                  <div class="modal-body">
                    <img loading="lazy" src="/img/home/SpinX.png" alt="gameimg" class="ww-100 mb-2" />
                    <div class="text-center">
                      <router-link data-dismiss="modal" style="background-color: var(--yellow);color: var(--red);"
                        class="btn ww-100 mb-2" :to="{ name: 'casinogames', params: { IsDemo: 0, providerID: 5, gameName: 'SpinX', gameID: 'SpinX', launchURL: 'https://eu-server.ssgportal.com', launchType: '', launchParameters: '' }, }">
                        Play Now
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>

    </div>

    <BottomNavigation></BottomNavigation>

  </div>

</template>

<script>
const BottomNavigation = () => import('./BottomNavigation.vue')
import casino from "@/services/casino";

export default {
  name: 'Home',
  data() {
    return {
      match_id: 0,
      game_id: 0,
      fixture: {},
      initial_fixture: {},
      market_group: {
        id: 0,
      },
      totals: [],
      live: false,
      seconds: 0,
      possible_win: "",
      payout: "",
      tax: "",
      odd_id: "",
      outcome: {},
      msisdn: "",
      password: "",
      code: "",
      action_verify_password: false,
      action_success: false,
      message: "",
      to_search: '',
      searchgamesdata: [],
      allgames: [],
      categories: [],
      casinocategories: [9, 4, 7, 12, 18, 17, 16],
      casinocategoriesObj: [
        {
          "id": 9,
          "title": "Popular"
        },
        {
          "id": 4,
          "title": "Slots"
        },
        // {
        //   "id" : 16,
        //   "title" : "Video Slots"
        // },
        // {
        //   "id" : 12,
        //   "title" : "Jackpot Games"
        // },
        {
          "id": 17,
          "title": "Classic Casino"
        },
        {
          "id": 8,
          "title": "Table Games"
        },
        // {
        //   "id" : 7,
        //   "title" : "Poker"
        // },
      ],
      casinoCategory: 9,
      casinoCategoryActive: {
        "id": 1,
        "name": "Crash"
      },
      casinoCategoryIndex: 0,
      disable: true,
      loading: false,
      touchstartX: 0,
      touchstartY: 0,
      touchendX: 0,
      touchendY: 0,
      currentPage: 1,
      perPage: 100,
    }
  },
  components: {
    BottomNavigation
  },
  mounted: function () {

    this.$store.dispatch("setCurrentPage", "home");
    // this.plinkoCasinoGames(this.casinoCategoryActive);
    this.reloadProfile();

    var categories = [];

    var vm = this;

    this.jQuery.each(vm.games, function (k, v) {
      var key = v.Category.replaceAll(/ /g, "");
      var exists = false;

      // lets check if category exists
      vm.jQuery.each(categories, function (i, x) {

        if (x.key == key) {

          x.games.push(v);
          exists = true;
        }

        categories[i] = x;
      });

      if (!exists) {

        var gm = [];
        gm.push(v);

        var cat = {
          name: v.Category,
          key: key,
          games: gm,
        };

        categories.push(cat);
      }
    });

    this.groupedGames = categories;

    this.getCategories();

    this.showSlides();

  },

  computed: {
    app: function () {
      return this.$store.state.app;
    },

    profile: function () {
      return this.getProfile();
    },
    token: function () {
      return this.getAuth();
    },
  },

  methods: {
    handleGesture: function () {

      var scrollLeft = document.getElementById('catsec').scrollLeft

      if (this.touchendX < this.touchstartX && (this.touchstartX - this.touchendX) > 125) {
        console.log('Swiped Left');
        console.log("start: " + this.touchstartX + "end: " + this.touchendX)
        let index = this.casinocategoriesObj.indexOf(this.casinoCategoryActive);
        let count = this.casinocategoriesObj.length;
        let newIndex = 0
        if (index < 0) {
          newIndex = 1;
        } else {
          newIndex = index + 1;
        }
        console.log("Old index: " + index)
        if (newIndex <= count) {
          this.casinoCategoryActive = this.casinocategoriesObj[newIndex];
          // this.plinkoCasinoGames(this.casinoCategoryActive);

          document.getElementById('catsec').scrollLeft = scrollLeft + 50;
        }
      }

      if (this.touchendX > this.touchstartX && (this.touchendX - this.touchstartX) > 125) {
        console.log('Swiped Right');

        console.log("start: " + this.touchstartX + "end: " + this.touchendX)
        let index = this.casinocategoriesObj.indexOf(this.casinoCategoryActive);
        // let count = this.casinocategoriesObj.length;
        if ((index - 1) > -1) {
          this.casinoCategoryActive = this.casinocategoriesObj[(index - 1)];
          this.plinkoCasinoGames(this.casinoCategoryActive);
          document.getElementById('catsec').scrollLeft = scrollLeft - 50;
        }

      }

      if (this.touchendY < this.touchstartY) {
        console.log('Swiped Up');
      }

      if (this.touchendY > this.touchstartY) {
        console.log('Swiped Down');
      }

      if (this.touchendY === this.touchstartY) {
        console.log('Tap');
      }
    },
    isActive: function (item) {
      // console.log(item)
      if (item.id == this.casinoCategoryActive.id) {
        return ' active';
      } else {
        return '';
      }
    },


    performSearch: function () {

      var keyword = this.to_search;
      var games = this.allgames;

      console.log(keyword);

      var results = games.filter(function (el) {

        console.log(el.game_name);
        var game_name = el.game_name.toLowerCase();

        return game_name.match(keyword.toLowerCase()) == null ? false : game_name.match(keyword.toLowerCase()).length > 0;

      });

      console.log('search results ' + JSON.stringify(this.searchgamesdata, undefined, 2))

      if (results.length > 0 && keyword !== "") {

        this.searchgamesdata = results;

      } else {

        this.searchgamesdata = [];

      }

    },

    keyUpSearch: function () {

      if (this.to_search == '') {

        this.searchgamesdata = [];

      }

    },

    loadPage: function (launch_url) {

      window.open(launch_url, '_blank');

    },

    getImg: function (img) {

      return img.replaceAll("/rec/325/", "/square/200/")

    },

    showSlides: function () {

      var vm = this;

      var slideIndex = 0;

      var i;
      var slides = document.getElementsByClassName("mySlides");
      var dots = document.getElementsByClassName("dot");

      for (i = 0; i < slides.length; i++) {

        slides[i].style.display = "none";
      }

      slideIndex++;

      if (slideIndex > slides.length) {

        slideIndex = 1;
      }

      for (i = 0; i < dots.length; i++) {

        dots[i].className = dots[i].className.replace(" active", "");

      }

      if (slides[slideIndex - 1]) {

        slides[slideIndex - 1].style.display = "block";
        dots[slideIndex - 1].className += " active";

      }

      setTimeout(function () {

        vm.showSlides();

      }, 2000)  // Change image every 2 seconds

    },

    getLaunchURL: function (launch_url) {

      var profile = this.getProfile();
      var tokn = profile.t1;

      // console.log('GOT launch_url '+launch_url);
      // console.log('GOT tokn '+tokn);

      launch_url = launch_url.replaceAll("profile_token", tokn);
      // console.log('GOT tokenized launch_url '+launch_url);

      return launch_url;

    },

    getKey: function (index) {

      return Math.random()
        .toString(10)
        .replace("0.", "games-id-" + index + "-");
    },

    generateGameUrl(game) {
      return `/casinogames?IsDemo=0&providerID=${game.provider_id}&gameName=${game.game_name}&gameID=${game.game_id}&launchURL=${game.launch_url}&launchType=${game.launch_type}&launchParameters=${game.launch_parameters}`;
    },

    closeModal() {
      const modals = document.getElementsByClassName('modal-window');
      for (let i = 0; i < modals.length; i++) {
        if (!modals[i].classList.contains('d-none')) {
          modals[i].classList.add('d-none');
          break;
        }
      }
    },

    // plinkoCasinoGames: function (activeCategory) {
    //   this.loading = true;
    //   this.plinkoCategoryActive = activeCategory;

    //   var vm = this;
    //   var path = "/games?page=" + this.currentPage + "&per_page=" + this.perPage + "&category_id=" + activeCategory.id;

    //   casino.get(path)
    //     .then((resp) => {
    //       vm.plinkogamedata = resp.data.data;
    //       vm.plinkogame = vm.plinkogamedata.slice(1, 2); // Limit to 4 games


    //       console.log("finalCasinoGame --> " + JSON.stringify(vm.plinkogame, undefined, 2));

    //       this.loading = false;

    //       window.scrollTo(0, 0);
    //     });
    // },

    getCategories: function () {

      var vm = this;

      var path = "/category";

      casino.get(path)
        .then(res => {

          vm.categories = res.data;

        })
        .catch(err => {

          if (err.response) {

            vm.setError("Failed", err.response.data.error_message)

          } else if (err.request) {

            console.log(JSON.stringify(err.request));

          } else {

            console.log(JSON.stringify(err));

          }
        })
    },

  },
  filters: {

    amount: function (val) {

      if (val === undefined) {
        return val;
      }

      return parseFloat(val).toFixed(2).toLocaleString();
    },
  },
};
</script>

<style scoped>
.row {
  row-gap: .5em;
}

.crash-game {
  padding: .2em;
}

.fly-game {
  text-align: center;
}

.app {
  height: 100% !important;
}

.rocket-fastball {
  height: 100%;
}

h5 {
  font-size: 1rem;
}

.rocket-fastball {
  height: 100%;
  width: auto;
}

.modal-window {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.25);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}

.modal-window:target {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.modal-window>div {
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2em;
  background: white;
}

.modal-window header {
  font-weight: bold;
}

.modal-window h1 {
  font-size: 150%;
  margin: 0 0 15px;
}

.modal-close {
  color: #aaa;
  line-height: 50px;
  font-size: 80%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 70px;
  text-decoration: none;
}

.modal-close:hover {
  color: black;
}
</style>