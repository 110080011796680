<template>

  <section class="boosted-odds-section flexer">
    <div class="b-o-t text-blue mb-2">
      BOOSTED GAMES
    </div>

    <div class="boosted-odds-wrapper d-flex">

      <div class="boosted-odds-card mr-1" v-for="f in highlightsRawData" v-bind:key="getKey(f.match_id)">

        <a style="text-decoration: none;">
          <div class="grey-text mb-2 league-text">
            <span v-text="f.tournament"></span> <span class="text-light mx-3">{{ formatMatchDate(f.date) }}</span> #<span v-text="f.game_id"></span>
          </div>
        </a>

        <div class="boosted-odds-game-row  m-0 px-2">

          <a style="text-decoration: none;">

            <div class="row m-0 p-0">

              <div class="col-6 p-0 m-0">
                <div class="text-light text-left text-home-away-team" v-text="f.home_team"></div>
              </div>

              <div class="col-6 p-0 m-0">
                <div class="text-light text-right text-home-away-team" v-text="f.away_team"></div>
              </div>

            </div>

          </a>

          <div class="row">
            <div
                v-bind:id="getID(o.odd_id)"
                v-for="o in f.outcomes"
                v-bind:key="getKey(o.odd_id)"
                class="col-4"
                v-bind:class="getClasses(o)">
              <Odd
                  v-bind:sport_id="f.sport_id"
                  v-bind:match_id="f.match_id"
                  v-bind:market_name="o.market_name"
                  v-bind:home_team="f.home_team"
                  v-bind:away_team="f.away_team"
                  v-bind:market_id="o.market_id"
                  v-bind:outcome_id="o.outcome_id"
                  v-bind:specifier="o.specifier"
                  v-bind:active="o.active"
                  v-bind:status="o.status"
                  v-bind:odds="o.odd"
                  v-bind:producer_id="o.producer_id"
                  v-bind:producer_status="o.producer_status"
                  v-bind:previous_odds="o.previous_odds"
                  v-bind:alias="o.alias"
                  v-bind:probability="o.probability"
                  boosted=true
                  v-bind:pick="o"/>
            </div>
          </div>
        </div>

      </div>

    </div>

  </section>

</template>

<script>
// import { RouterLink } from "vue-router";
import Odd from "./Odd";
import fix from "@/services/fix";
import mqtt from "mqtt";


export default {
  name: "Boosted",
  methods: {
    toURL: function (input) {
      return input.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
    },
    getID: function (id) {
      return "boosted-" + id;
    },
    getRouterLink: function (fixture) {
      return {
        name: 'match',
        params: {
          game_id: fixture.game_id === undefined ? 0 : fixture.game_id,
          name: this.toURL(fixture.name),
          status: fixture.live ? 'live' : 'prematch'
        }
      };
    },
    initMqtt: function () {

      //return this.registerPoll();

      var vm = this;

      if (this.mqttClient !== false) {

        this.mqttClient.end();

      }

      const options = {
        clean: true, // retain session
        connectTimeout: 4000, // Timeout period
        clientId: this.getClientID(),
        username: "uiuser",
        password: "prduser",
      }
      var host = "wss://ws-v2.ponyoka.com/mqtt"

      var topic_betstop = 'topic/producer-3/sport-' + this.fixture.sport_id + '/status/match-' + this.fixture.match_id + '/#';
      var topic_match = 'topic/producer-3/sport-' + this.fixture.sport_id + '/status/match-' + this.fixture.match_id
      //var topic_others = 'topic/producer-3/sport-'+this.fixture.sport_id+'/betstop/match-'+this.fixture.match_id;
      //var topic_match = 'topic/producer-3/sport-'+this.fixture.sport_id+'/match/match-'+this.fixture.match_id;

      var client = mqtt.connect(host, options);

      client.on('connect', function () {

        client.subscribe(topic_betstop, function (err) {
          if (!err) {

            // console.log('subscribed to topic '+topic_betstop);

          }
        })
        /*

        client.subscribe(topic_others, function (err) {
          if (!err) {

            console.log('subscribed to topic '+topic_others);

          }
        })
        */

        client.subscribe(topic_match, function (err) {
          if (!err) {

            // console.log('subscribed to topic '+topic_match);

          }
        })

        var topic2 = 'topic/producer';
        client.subscribe(topic2, function (err) {
          if (!err) {

            // console.log('subscribed to topic '+topic2);

          }
        })

      })

      client.on('message', function (topic, msg) {
        // message is Buffer
        var payload = JSON.parse(msg.toString());
        vm.uxUpdate(payload);
      })

      //this.poll(topic_betstop);

      this.mqttClient = client;

    },

    listenForOddsChange: function () {
      var vm = this;

      vm.EventBus.$on(
        "match:reload:" + this.fixture.match_id,
        function (updates) {
          vm.updateFixture(updates);
        }
      );

      /*
      vm.EventBus.$on('match:update:'+this.fixture.match_id,function(updates){

        vm.updateMatch(updates);

      });
       */
    },
    isGameLive(fixture) {
      return fixture.producer_id === 1 || fixture.producer_id === 4;
    },

    getPopularGames: function () {
      var vm = this;

      var path = process.env.VUE_APP_BASE_FIXTURE_URL + "/highlights/{sport_id}";
      path = path.replace("{sport_id}", vm.sport_id);

      if (parseInt(vm.page) > 0 && parseInt(vm.page) > parseInt(vm.last_page)) {
        vm.is_busy = false;
        return;
      }

      if (vm.is_busy) {
        return;
      }

      vm.page = parseInt(vm.page) + 1;

      vm.is_busy = true;
      vm.EventBus.$emit("event:busy", true);

      var dat = {
        page: vm.page,
        per_page: vm.per_page,
        highlight_market_id: vm.market_id,
        tournament_id: vm.competition_id,
        category_id: vm.category_id,
        upcoming: 0,
        boosted: 1,
        today: 0
      };

      fix
          .get(path, { params: dat }) // Pass dat as params directly
          .then((res) => {
            vm.is_busy = false;
            vm.EventBus.$emit("event:busy", false);

            var highlightsRawData = res.data.data;
            vm.last_page = res.data.last_page;
            vm.remaining_records = res.data.remaining_records;

            if (parseInt(vm.page) === 1) {
              vm.fixtures = []; // Reset fixtures array
            }

            highlightsRawData.forEach((highlight) => {
              // Restructure the data and push it to fixtures array
              vm.highlightsRawData.push({
                tournament: highlight.tournament,
                sport_id: highlight.sport_id.toString(),
                game_id: highlight.game_id.toString(),
                name: highlight.name,
                match_id: highlight.match_id.toString(),
                date: highlight.date,
                total_markets: highlight.total_markets,
                ActiveMarkets: highlight.active_markets,
                competition_id: highlight.tournament_id,
                status_code: highlight.status_code,
                status: highlight.status,
                live_coverage: 0,
                market_name: highlight.highlight_market.market_name,
                country: highlight.country,
                country_code: "",
                event_time: "",
                home_score: highlight.home_score,
                match_status: highlight.match_status,
                event_status: "",
                away_score: highlight.away_score,
                competitor2_flag: "",
                competitor1_flag: "",
                home_team: highlight.home_team,
                away_team: highlight.away_team,
                outcomes: highlight.highlight_market.outcomes.map(outcome => ({
                  alias: outcome.alias,
                  market_name: highlight.highlight_market.market_name,
                  market_id: highlight.highlight_market.market_id,
                  outcome_name: outcome.outcome_name,
                  specifier: highlight.highlight_market.specifier,
                  outcome_id: outcome.outcome_id.toString(),
                  odd: outcome.odds.toString(),
                  odds: outcome.odds.toString(),
                  previous_odds: outcome.previous_odds.toString(),
                  odd_id: outcome.odd_id.toString(),
                  direction: outcome.direction.toString(),
                  status: outcome.status,
                  active: outcome.active,
                  producer_id: highlight.producer_id.toString(),
                  producer_status: highlight.producer_status.toString(),
                  probability: outcome.probability.toString()
                }))
              });

            });
          })
          .catch((err) => {
            vm.EventBus.$emit("event:busy", false);
            vm.is_busy = false;

            if (err.response) {
              vm.setError("Failed", err.response.data.message);
            } else if (err.request) {
              console.error(JSON.stringify(err.request));
            } else {
              console.error(JSON.stringify(err));
            }
          });
    },

    getPopularGames12333: function () {
      var vm = this;
      var dat = {
        page: vm.page,
        per_page: vm.per_page,
        highlight_market_id: vm.market_id,
        tournament_id: vm.competition_id,
        category_id: vm.category_id,
        upcoming: 0,
        boosted: 1,
        today: 0
      };

      var path = process.env.VUE_APP_BASE_FIXTURE_URL;
      path = path.replace("{sport_id}", vm.sport_id);

      fix
          .get(path, { params: dat })
          .then((res) => {
            // console.log("SUCCESSFUL DATA GOTTEN", res);
            vm.highlightsRawData = res.data.data;
          })
          .catch((err) => {
            if (err.response) {
              vm.setError("Failed", err.response.data.message);
            } else if (err.request) {
              console.log(JSON.stringify(err.request));
            } else {
              console.log(JSON.stringify(err));
            }
          });
    },

    getClasses: function (o) {
      //var st = parseInt(o.active) === 0 || parseInt(o.status) !== 0 ? 'btn-odd' : 'btn-odd';

      return (
        "" +
        " match-" +
        o.match_id +
        " producer-" +
        o.producer_id +
        " market-" +
        o.market_id +
        " outcome-" +
        o.outcome_id +
        " specifier-" +
        o.specifier
      );
    },

    getKey: function (match_id) {
      var prefix = "popular-games-" + match_id;
      return Math.random()
        .toString(10)
        .replace("0.", "odd-id-" + prefix + "-");
    },
    setSport: function (sportID) {
      this.$store.dispatch("setSportID", sportID);

      if (this.$store.state.current_page === "home") return;

      this.$router.push({ name: "home" });
    },
    getMenuTextClass: function (sportID) {
      return this.current_page === "esport" || this.current_page === "freebet"
        ? ""
        : parseInt(this.$store.state.sport_id) === parseInt(sportID)
          ? "menu-active"
          : "";
    },
    isActiveMenu: function (name) {
      return this.current_page === name ? "menu-active" : "";
    },
    resetGames: function () {
      this.$store.dispatch("resetAllGames");

      //if(this.current_page !== "home") {

      this.goHome();

      //}
    },
    updateFixture: function (updates) {

      var vm = this;
      var fixture = updates;
      var initial_fixture = updates;

      vm.live = parseInt(updates.producer_id) === 1;

      var formatted_18 = {};
      var formatted_238 = {};
      var formatted_166 = {};

      vm.jQuery.each(initial_fixture.markets, function (k, v) {
        var market_id = parseInt(v.market_id);
        var status = v.status;
        var specifier =
          v.specifiers === undefined || v.specifiers === null
            ? ""
            : v.specifiers;
        var outcome = v.outcome;

        if (parseInt(market_id) === 18) {
          vm.jQuery.each(outcome, function (k1, v1) {
            var outcome_id = v1.outcome_id;
            var over = "over";
            var under = "under";

            //chck if key does not exist and create it
            if (
              formatted_18[specifier] === null ||
              !formatted_18[specifier] ||
              formatted_18[specifier] === false ||
              formatted_18[specifier] === undefined
            ) {
              formatted_18[specifier] = {};
              formatted_18[specifier][over] = {};
              formatted_18[specifier][under] = {};
            }

            if (parseInt(outcome_id) === 13) {
              formatted_18[specifier][under] = v1;
            }

            if (parseInt(outcome_id) === 12) {
              formatted_18[specifier][over] = v1;
            }

            var id = vm.getOddID(
              initial_fixture.match_id,
              market_id,
              v1.outcome_id,
              specifier,
              "odd"
            );
            var oddsChangedPayload = {};
            oddsChangedPayload.id = id;
            oddsChangedPayload.odds = v1.odd;
            oddsChangedPayload.status = status;
            oddsChangedPayload.active = v1.active;
            oddsChangedPayload.previous_odds = v1.odd;
            vm.EventBus.$emit("odds:changed", oddsChangedPayload);
          });
        } else if (parseInt(market_id) === 238) {
          vm.jQuery.each(outcome, function (k1, v1) {
            var outcome_id = v1.outcome_id;
            var over = "over";
            var under = "under";

            if (
              formatted_238[specifier] === null ||
              !formatted_238[specifier] ||
              formatted_238[specifier] === false ||
              formatted_238[specifier] === undefined
            ) {
              formatted_238[specifier] = {};
              formatted_238[specifier][over] = {};
              formatted_238[specifier][under] = {};
            }

            if (parseInt(outcome_id) === 13) {
              formatted_238[specifier][under] = v1;
            }

            if (parseInt(outcome_id) === 12) {
              formatted_238[specifier][over] = v1;
            }

            var id = vm.getOddID(
              initial_fixture.match_id,
              market_id,
              v1.outcome_id,
              specifier,
              "odd"
            );
            var oddsChangedPayload = {};
            oddsChangedPayload.id = id;
            oddsChangedPayload.odds = v1.odd;
            oddsChangedPayload.status = status;
            oddsChangedPayload.active = v1.active;
            oddsChangedPayload.previous_odds = v1.odd;
            vm.EventBus.$emit("odds:changed", oddsChangedPayload);
          });
        } else if (parseInt(market_id) === 166) {
          vm.jQuery.each(outcome, function (k1, v1) {
            var outcome_id = v1.outcome_id;
            var over = "over";
            var under = "under";

            if (
              formatted_166[specifier] === null ||
              !formatted_166[specifier] ||
              formatted_166[specifier] === false ||
              formatted_166[specifier] === undefined
            ) {
              formatted_166[specifier] = {};
              formatted_166[specifier][over] = {};
              formatted_166[specifier][under] = {};
            }

            if (parseInt(outcome_id) === 13) {
              formatted_166[specifier][under] = v1;
            }

            if (parseInt(outcome_id) === 12) {
              formatted_166[specifier][over] = v1;
            }

            var id = vm.getOddID(
              initial_fixture.match_id,
              market_id,
              v1.outcome_id,
              specifier,
              "odd"
            );
            var oddsChangedPayload = {};
            oddsChangedPayload.id = id;
            oddsChangedPayload.odds = v1.odd;
            oddsChangedPayload.status = status;
            oddsChangedPayload.active = v1.active;
            oddsChangedPayload.previous_odds = v1.odd;
            vm.EventBus.$emit("odds:changed", oddsChangedPayload);
          });
        } else {
          vm.jQuery.each(outcome, function (k1, v1) {
            var id = vm.getOddID(
              initial_fixture.match_id,
              market_id,
              v1.outcome_id,
              specifier,
              "odd"
            );
            var oddsChangedPayload = {};
            oddsChangedPayload.id = id;
            oddsChangedPayload.odds = v1.odd;
            oddsChangedPayload.status = status;
            oddsChangedPayload.active = v1.active;
            oddsChangedPayload.previous_odds = v1.odd;
            vm.EventBus.$emit("odds:changed", oddsChangedPayload);
          });
        }
      });

      var totals_18 = formatted_18;
      var totals_238 = formatted_238;
      var totals_166 = formatted_166;

      fixture.totals_18 = totals_18;
      fixture.totals_238 = totals_238;
      fixture.totals_166 = totals_166;

      vm.$store.dispatch("setFixture", fixture);
      vm.autoRefreshUI(vm.$vnode.tag);
    },
    updateMarket: function (updates) {

      var vm = this;
      var fixture = updates;
      var initial_fixture = updates;

      vm.live = parseInt(updates.producer_id) === 1;

      var formatted_18 = {};
      var formatted_238 = {};
      var formatted_166 = {};

      vm.jQuery.each(initial_fixture.markets, function (k, v) {
        var market_id = parseInt(v.market_id);
        var status = v.status;
        var specifier =
          v.specifiers === undefined || v.specifiers === null
            ? ""
            : v.specifiers;
        var outcome = v.outcome;

        if (parseInt(market_id) === 18) {
          vm.jQuery.each(outcome, function (k1, v1) {
            var outcome_id = v1.outcome_id;
            var over = "over";
            var under = "under";

            //chck if key does not exist and create it
            if (
              formatted_18[specifier] === null ||
              !formatted_18[specifier] ||
              formatted_18[specifier] === false ||
              formatted_18[specifier] === undefined
            ) {
              formatted_18[specifier] = {};
              formatted_18[specifier][over] = {};
              formatted_18[specifier][under] = {};
            }

            if (parseInt(outcome_id) === 13) {
              formatted_18[specifier][under] = v1;
            }

            if (parseInt(outcome_id) === 12) {
              formatted_18[specifier][over] = v1;
            }

            var id = vm.getOddID(
              initial_fixture.match_id,
              market_id,
              v1.outcome_id,
              specifier,
              "odd"
            );
            var oddsChangedPayload = {};
            oddsChangedPayload.id = id;
            oddsChangedPayload.odds = v1.odd;
            oddsChangedPayload.status = status;
            oddsChangedPayload.active = v1.active;
            oddsChangedPayload.previous_odds = v1.odd;
            vm.EventBus.$emit("odds:changed", oddsChangedPayload);
          });
        } else if (parseInt(market_id) === 238) {
          vm.jQuery.each(outcome, function (k1, v1) {
            var outcome_id = v1.outcome_id;
            var over = "over";
            var under = "under";

            if (
              formatted_238[specifier] === null ||
              !formatted_238[specifier] ||
              formatted_238[specifier] === false ||
              formatted_238[specifier] === undefined
            ) {
              formatted_238[specifier] = {};
              formatted_238[specifier][over] = {};
              formatted_238[specifier][under] = {};
            }

            if (parseInt(outcome_id) === 13) {
              formatted_238[specifier][under] = v1;
            }

            if (parseInt(outcome_id) === 12) {
              formatted_238[specifier][over] = v1;
            }

            var id = vm.getOddID(
              initial_fixture.match_id,
              market_id,
              v1.outcome_id,
              specifier,
              "odd"
            );
            var oddsChangedPayload = {};
            oddsChangedPayload.id = id;
            oddsChangedPayload.odds = v1.odd;
            oddsChangedPayload.status = status;
            oddsChangedPayload.active = v1.active;
            oddsChangedPayload.previous_odds = v1.odd;
            vm.EventBus.$emit("odds:changed", oddsChangedPayload);
          });
        } else if (parseInt(market_id) === 166) {
          vm.jQuery.each(outcome, function (k1, v1) {
            var outcome_id = v1.outcome_id;
            var over = "over";
            var under = "under";

            if (
              formatted_166[specifier] === null ||
              !formatted_166[specifier] ||
              formatted_166[specifier] === false ||
              formatted_166[specifier] === undefined
            ) {
              formatted_166[specifier] = {};
              formatted_166[specifier][over] = {};
              formatted_166[specifier][under] = {};
            }

            if (parseInt(outcome_id) === 13) {
              formatted_166[specifier][under] = v1;
            }

            if (parseInt(outcome_id) === 12) {
              formatted_166[specifier][over] = v1;
            }

            var id = vm.getOddID(
              initial_fixture.match_id,
              market_id,
              v1.outcome_id,
              specifier,
              "odd"
            );
            var oddsChangedPayload = {};
            oddsChangedPayload.id = id;
            oddsChangedPayload.odds = v1.odd;
            oddsChangedPayload.status = status;
            oddsChangedPayload.active = v1.active;
            oddsChangedPayload.previous_odds = v1.odd;
            vm.EventBus.$emit("odds:changed", oddsChangedPayload);
          });
        } else {
          vm.jQuery.each(outcome, function (k1, v1) {
            var id = vm.getOddID(
              initial_fixture.match_id,
              market_id,
              v1.outcome_id,
              specifier,
              "odd"
            );
            var oddsChangedPayload = {};
            oddsChangedPayload.id = id;
            oddsChangedPayload.odds = v1.odd;
            oddsChangedPayload.status = status;
            oddsChangedPayload.active = v1.active;
            oddsChangedPayload.previous_odds = v1.odd;
            vm.EventBus.$emit("odds:changed", oddsChangedPayload);
          });
        }
      });

      var totals_18 = formatted_18;
      var totals_238 = formatted_238;
      var totals_166 = formatted_166;

      fixture.totals_18 = totals_18;
      fixture.totals_238 = totals_238;
      fixture.totals_166 = totals_166;

      vm.$store.dispatch("setFixture", fixture);
      vm.autoRefreshUI(vm.$vnode.tag);
    },
    getOddDirectionClass: function (outcome) {
      if (parseInt(outcome.active) === 0 || parseInt(outcome.status) != 1) {
        return "";
      }

      if (parseFloat(outcome.odd) >= parseFloat(outcome.previous_odds)) {
        return "arrow green up";
      }

      return "arrow red down";
    },
  },
  computed: {
    sportIcon: function () {
      return this.getSportIcon(this.sport_id);
    },
    iconSize: function () {
      return 24;
    },
    current_page: function () {
      return this.$store.state.current_page;
    },
    show_freebet: function () {
      var p = this.getProfile();
      if (!p) {
        return true;
      }

      return parseInt(p.b4) > 0;
    },
    sports: function () {
      var s = this.$store.state.sports;
      var t = [];

      this.jQuery.each(s, function (k, v) {
        if (v.sport_id > 1) {
          t.push(v);
        }
      });

      return t;
    },
    sport_id: function () {
      return this.$store.state.sport_id;
    },
    sport_name: function () {
      return this.$store.state.sport_name;
    },
  },
  components: {
    Odd
    // RouterLink
  },
  watch: {
    sport_id: function (newValue, oldValue) {
      if (parseInt(newValue) === parseInt(oldValue)) {
        return;
      }

      this.getPopularGames();
    },
  },
  data: function () {
    return {
      per_page: 20,
      page: 0,
      last_page: 0,
      remaining_records: 0,
      highlightsRawData: [],
    };
  },
  mounted: function () {
    this.getPopularGames();
  },
};
</script>